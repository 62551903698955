const styles = {
    body: {
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden' // Remove a rolagem
    },
    backgroundContainer: {
        position: 'relative', // Permite posicionamento absoluto da barra de progresso
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Ocupa a altura total da tela
        width: '100vw',  // Ocupa a largura total da tela
        overflow: 'hidden' // Remove a rolagem
    },
    backgroundImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto',
        display: 'block' // Remove espaços em branco adicionais
    },
    progressContainer: {
        position: 'absolute',
        top: '50%', // Centraliza verticalmente
        left: '50%', // Centraliza horizontalmente
        transform: 'translate(-50%, -50%)', // Ajusta a posição para o centro exato
        display: 'flex',
        flexDirection: 'column', // Organiza os elementos em coluna
        alignItems: 'center', // Centraliza os elementos verticalmente
        width: '100%', // Largura total do container
        textAlign: 'center' // Centraliza o texto horizontalmente
    },
    progressBarContainer: {
        width: '80%', // Largura da barra de progresso
        height: '20px', // Altura da barra de progresso
        backgroundColor: '#ccc', // Cor de fundo da barra de progresso
        borderRadius: '8px', // Cantos arredondados
        overflow: 'hidden', // Esconde qualquer transbordamento
        marginTop: '10px' // Espaçamento entre o texto e a barra de progresso
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#FFA500', // Cor da barra de progresso
        transition: 'width 0.1s linear', // Transição suave da largura da barra
        width: '100%' // Largura da barra de progresso (se ajusta ao width do progressBarContainer)
    },
    loadingMessage: {
        color: '#000', // Cor do texto
        fontSize: '20px', // Tamanho da fonte aumentado
        fontWeight: 'bold', // Texto em negrito
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)', // Sombra sutil
        marginBottom: '10px' // Espaçamento inferior
    }
};

export default styles;
